import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/variables';
import poirlogo from 'assets/images/poir.png';

const Project = ({ location }) => {
   const headerText = ['Inteligentny rozwój'];

   const ContainerBlock = styled.div`
     width: 100%;
     max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '1100px')};
     padding: ${({ fullwidth }) => (fullwidth ? '0' : '0 15px')};
     margin: 0 auto;
     justify-content: center;

     ${breakpoints.xl`
        padding: 0;
     `}
  `;

   return (
      <>
         <SEO title="Inteligentny rozwój" />
         <PageHeader headerText={headerText} />
         <ContainerBlock>
            <img alt="poirlogo" src={poirlogo} className="poirLogo" style={{width: "100%"}} />
         </ContainerBlock>
         <ContainerBlock>
           <p>
            Lab Contorl spółka z ograniczoną odpowiedzialnością w konsorcjum z Instytutem
            Biotechnologii i Medycyny Molekularnej i Gormanet spółka z ograniczona odpowiedzialnością
            realizuje projekt dofinansowany z Funduszy Europejskich
            pt. „PostureScan – system oparty o uczenie maszynowe służący do analizy, interpretacji wad
            postawy u dzieci i młodzieży”
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
            <b>Cel projektu</b><br></br>
            Celem projektu „PostureScan” jest opracowanie technologii i metodologii opartej o metody Uczenia Maszynowego, pozwalające przedstawicielom zawodów medycznych: pielęgniarkom, fizjoterapeutom, lekarzom na sprawną i efektywną
            ocenę postawy u dzieci, wyszukiwanie wad postawy oraz deformacji na wczesnym ich etapie, co pozwoli wprowadzić nową
            jakość w zapobieganiu następstwom wad postawy w życiu dorosłym lub narastaniu deformacji. System ograniczy do
            minimum ewentualne pomyłki podczas oceny wizualnej i pozwoli na wczesne wykrywanie potencjalnych wad postawy,
            oraz jej obserwację w czasie. Pozwoli na śledzenie remisji lub nasilenia zmian, co połączone z dostosowaną do tego pracą
            rehabillitacyjną umożliwi stworzenie powszechnego systemu oraz nowego standardu opieki nad dziećmi z wadami
            postawy. “Posture Scan” umożliwi dokładniejsze i szybsze przeprowadzania badan przesiewowych w przedszkolach,
            szkołach podstawowych i szkołach średnich co pozwoli na zmniejszenie kosztów badania i umożliwi dokładniejszą analizę i
            bardziej spersonalizowane sugestie dotyczące rehabilitacji wybraneg
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
             <b>Planowane efekty</b><br></br>
             Rezultatem projektu będzie stworzenie metodologii badania i technologii opartej o techniki Uczenia Maszynowego w formie
             systemu i metodologii badania, która usprawni proces definiowania przesłanek o wadach postawy i deformacjach u dzieci i
             młodzieży. System zostanie wsparty pomiarami z pedobarografu, stereoskopią, lidografią i rollupem zaprojektowanym do
             wsparcia procesu wyznaczania superpixeli. Posture Scan będzie narzędziem dedykowanym przedstawicielom zawodów
             medycznych tj; pielęgniarkom, fizjoterapeutom, ortopedom i lekarzom rodzinnym, zajmującym się badaniem wad postawy
             ciała podczas badan indywidualnych i badan przesiewowych. Posture Scan umożliwi szybką analizę i precyzyjną ocenę
             postawy u badanego pacjenta. Poza elementem badania stanu obecnego pacjenta badanego Posture Scan będzie tworzyło
             tzw. Historię postawy pacjenta badanego, w której widoczny będzie progres lub regres wybranej patologii postawy ciała co
             znacząco przyczyni się do dobierania odpowiedniego postępowania mającego na celu leczenie i zapobieganie dalszym
             schorzeniom. Posture Scan jako narzędzie, którego głównym przeznaczeniem będzie analizowanie skoliozy i wad postawy u
             młodych osób (dzieci między 3 a 18) będzie spełniało funkcję prewencyjną we wczesnym wykrywaniu wad postawy u
             badanych osób, jednocześnie minimalizując potencjalne ryzyko powikłań. Opracowana metodologia pracy z młodympacjentem pozwoli stworzyć nowy standard opieki nad wykrywaniem i obserwacją wad postawy w czasie, co skutkować
             będzie obniżonymi kosztami opieki w życiu dorosłym i polepszeniem standardu życia. Wykorzystanie technologii będzie
             atrakcyjne dla podmiotów realizujących programy profilaktyki wad postawy w kraju i na świecie. Infrastruktura technologii ze
             względu na swoją formę, ma potencjał wsparcia diagnostyki wad postawy w krajach i regionach słabiej rozwiniętych.
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
             <b>Wartość projektu:</b> 8 482 620,72 zł<br></br>
             <b>Wkład funduszy europejskich:</b> 7 217 653,06 zł<br></br>
           </p>
         </ContainerBlock>
      </>
   );
};

export default Project;
